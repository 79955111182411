import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import ProjektList from '../components/ProjektList'
import BloggListSection from '../components/bloglist-section'
import SEO from '../components/seo'

const Index = ({ data }) => {
  return (
    <Layout className="">
      <SEO />
      <ProjektList />
      <BloggListSection data={data} className="" link={true} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {

    allMdx(sort: {fields: frontmatter___date, order: DESC}, limit: 4) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMM YYYY")
            subtitle
            path
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
export default Index